import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {GenerateReportKmComponent} from "./generate-report-km/generate-report-km.component";
import {AdminControlPanelComponent} from "./admin-control-panel/admin-control-panel.component";
import {GenerateFactureComponent} from "./generate-facture/generate-facture.component";
import {UserCreatorComponent} from "./user-creator/user-creator.component";
import {UserMainInterfaceComponent} from "./user-main-interface/user-main-interface.component";
import {MenuComponent} from "./menu/menu.component";
import {AuthGuard} from "./AuthGuard";
import {PassWordChangerComponent} from "./pass-word-changer/pass-word-changer.component";

const routes: Routes = [

  {path: 'login', component: LoginComponent},


  {
    path: 'main',
    component: UserMainInterfaceComponent,
    canActivate: [AuthGuard]
  },
  {path: 'generate-report-km', component: GenerateReportKmComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminControlPanelComponent, canActivate: [AuthGuard]},
  {path: 'generate-facture', component: GenerateFactureComponent, canActivate: [AuthGuard]},
  {path: 'add-user', component: UserCreatorComponent, canActivate: [AuthGuard]},
  {path: 'update-password', component: PassWordChangerComponent, canActivate: [AuthGuard]},
  {path: '', component: MenuComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
