<app-menu>


  <div class="user-main-interface">
    <h4>Bonjour, James</h4>
    <div class="user-main-interface__container">
      <div class="user-main-interface__container__notifications" style="flex-grow: 4;">
        <h5>NOTIFICATIONS</h5>
        <mat-card class="example-card">
          <mat-card-subtitle>Wallopoly</mat-card-subtitle>
          <mat-card-title>Prestations non validés</mat-card-title>
          <mat-card-content>
            <p>Les prestations suivantes ne sont pas encore validées</p>
            <mat-list-item>Laurent Gerra,15/06/2020</mat-list-item>
            <mat-list-item>Dany Boon,17/06/2020</mat-list-item>
            <mat-list-item>Kev Adams,25/06/2020</mat-list-item>
          </mat-card-content>
          <mat-divider inset></mat-divider>
          <mat-card-actions>
            <button>Voir les prestations</button>
          </mat-card-actions>
        </mat-card>
        <mat-card>
          <mat-card-subtitle>Admin</mat-card-subtitle>
          <mat-card-title>Formatage des adresses</mat-card-title>
          <mat-card-content>
            <p>547 adresses restantes</p>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="user-main-interface__container__usefull-actions" style="flex-grow: 4;">
        <h5>ACTIONS UTILES</h5>
        <button [routerLink]="['/update-password']">Modifier mon mot de passe</button>
        <button [routerLink]="['/add-user']">Ajouter utilisateur</button>
        <button [routerLink]="['/admin']">Admin</button>
      </div>
    </div>
  </div>
</app-menu>
