import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Config} from "../models/ConfigModel";
import {ConfigLoaderService} from "../services/config-loader.service";
import {MatSnackBar} from '@angular/material/snack-bar';
export interface ApiRoutes {
  name: string;
  url: string;
}
const ELEMENT_DATA :ApiRoutes[] =[
  {"name":"checkCoordinates","url":"/api/address/checkcoordinates/"},
  {"name":"update CirepPersonnelDeplacement","url":"/api/address/update/CirepPersonnelDeplacement"},
  {"name":"update Personne","url":"/api/address/update/Personne"},
  {"name":"update WallopolyPrestation","url":"/api/address/update/WallopolyPrestation"},
  {"name":"update WallopolyPrestationDeplacement","url":"/api/address/update/WallopolyPrestationDeplacement"},
  {"name":"update WallopolyPersonnelDeplacement","url":"/api/address/update/WallopolyPersonnelDeplacement"},
  {"name":"update WallopolyLoisir","url":"/api/address/update/WallopolyLoisir"},
  {"name":"update all","url":"/api/address/update/all"},
  {"name":"update Trajet","url":"/api/address/update/Trajet"}
];
@Component({
  selector: 'app-admin-control-panel',
  templateUrl: './admin-control-panel.component.html',
  styleUrls: ['./admin-control-panel.component.scss']
})
export class AdminControlPanelComponent implements OnInit {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');
  private options = {headers: this.headers, withCredentials: true};
  private config: Config;
  requesting = false;
  startId:number;
  endId:number;
  constructor(private http: HttpClient,configLoader: ConfigLoaderService,private _snackBar: MatSnackBar) {
    this.config = configLoader.getConfigData();
  }
  ngOnInit(): void {
  }
  displayedColumns: string[] = ['name','url'];
  dataSource = ELEMENT_DATA;

  public requestUpdate(requestUrl:string)
  {
    if(requestUrl =="/api/address/checkcoordinates/" )
    {
      requestUrl=requestUrl+this.startId+"/and/"+this.endId;
    }
    this.requesting=true;
    this.http.get<any>(this.config.host+requestUrl, this.options).subscribe(
      response => {
        this.requesting=false;
        this.openSnackBar("Traitement terminé","close");
      },
      error => {
        this.requesting=false;
        this.openSnackBar(error.error.exception+' '+error.error.message,"close");
      }
    )
  }
  public openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
}
