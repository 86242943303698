import { Injectable } from '@angular/core';
import {ConfigLoaderService} from "./config-loader.service";
import {Config} from "../models/ConfigModel";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private config: Config;
  constructor(configLoader: ConfigLoaderService,private http: HttpClient) {
    this.config = configLoader.getConfigData();
  }
  public generateAndDownloadPrestationInvoice( contactId:number,beneficiaryId:number,year:number,month:number) {

    location.href = this.config.host + '/api/invoice/' + contactId + '/' + beneficiaryId + '/' + year + '/' + month;
  }
  public   fetchBeneficiairesByMonth( month: number,year:number): any {
    return this.http.get<any>(this.config.host+'/api/invoice/beneficiariesToInvoice/'+month+'/'+year).toPromise();

  }
}
