import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {throwError} from "rxjs";
import {Router} from "@angular/router";
import {Config} from "../models/ConfigModel";
import {ConfigLoaderService} from "../services/config-loader.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  static host: string;

  username: string;
  password: string;
  private config: Config;
  isForgetPassWordHidden = true;

  constructor(private http: HttpClient, private route: Router, configLoader: ConfigLoaderService) {
    this.config = configLoader.getConfigData();
    LoginComponent.host = this.config.host;

  }

  ngOnInit(): void {
    // nothing to do yet
  }

  onSubmit(form: NgForm) {
    // nothing to do yet
  }

  public login(): any {

    const data = {
      "email": this.username,
      "password": this.password
    };


    this.http.get(LoginComponent.host + "/sanctum/csrf-cookie").subscribe(
      (getResponse,) => {
        console.log(getResponse);

        this.http.post<any>(LoginComponent.host + "/login", data).subscribe(
          (postResponse) => {
            localStorage.setItem('currentUser', 'true');
            this.navigateToUserMainInterfacePage();
            console.log(postResponse);
          },
          e => {
            throwError(e);
          }
        );
      },
      e => {
        throwError(e);
      }
    );
  }

  navigateToUserMainInterfacePage() {
    this.route.navigate(['main']);
  }

  forgottenPassWord() {
    this.isForgetPassWordHidden = !this.isForgetPassWordHidden;
  }

}
