import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TableauHoraireComponent } from './tableau-horaire/tableau-horaire.component';
import { PrestationComponent } from './prestation/prestation.component';
import { EducateurComponent } from './educateur/educateur.component';
import {EducateurService} from "./services/educateur.service";
import {PrestationService} from "./services/prestation.service";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {FormsModule} from '@angular/forms';
import { HomeComponent } from './home/home.component'
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from './login/login.component';
import { GenerateReportKmComponent } from './generate-report-km/generate-report-km.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {ConfigLoaderService} from "./services/config-loader.service";
import { AdminControlPanelComponent } from './admin-control-panel/admin-control-panel.component';
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatInputModule} from "@angular/material/input";
import { ForgetPassWordComponent } from './forget-pass-word/forget-pass-word.component';
import { MenuComponent } from './menu/menu.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import { NavbarComponent } from './navbar/navbar.component';
import { UserCreatorComponent } from './user-creator/user-creator.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from '@angular/material/core';
import { PassWordChangerComponent } from './pass-word-changer/pass-word-changer.component';
import { UserMainInterfaceComponent } from './user-main-interface/user-main-interface.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { GenerateFactureComponent } from './generate-facture/generate-facture.component';
import {AuthGuard} from "./AuthGuard";
import {RepitApiInterceptor} from "./repitApi.interceptor";
import { BenefeciaryCardComponent } from './benefeciary-card/benefeciary-card.component';

export function configProviderFactory(provider: ConfigLoaderService) {
  return () => provider.load();
}
const appRoutes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'horaire', component: TableauHoraireComponent}
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TableauHoraireComponent,
    PrestationComponent,
    EducateurComponent,
    HomeComponent,
    GenerateReportKmComponent,
    AdminControlPanelComponent,
    ForgetPassWordComponent,
    MenuComponent,
    NavbarComponent,
    UserCreatorComponent,
    PassWordChangerComponent,
    UserMainInterfaceComponent,
    GenerateFactureComponent,
    BenefeciaryCardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    MatButtonToggleModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configProviderFactory, deps: [ConfigLoaderService], multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RepitApiInterceptor,
      multi: true
    },
    EducateurService,
    PrestationService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
